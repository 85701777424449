export default function InputWrapper({ icon, children, errorMessage }) {
  return (
    <div className={`input-wrapper relative w-full bg-white ${errorMessage ? 'with-errors' : ''}`}>
      {icon}
      <div className="relative">
        {children}
      </div>
      {errorMessage && (
        <label className="error-label absolute px-2 py-1 text-white">{errorMessage}</label>
      )}
    </div>
  );
}
